import React from 'react'
import PropTypes from 'prop-types'

export function TabContent({ title, description, image }) {
  return (
    <div className={'tab-pane fade active in '}>
      <div className="col-xs-4 img-column">
        <img src={image} alt="" className="img-responsive" />
      </div>
      <div className="col-sm-7 align-left">
        <h6>{title}</h6>
        <p>{description}</p>
      </div>
    </div>
  )
}

TabContent.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}
