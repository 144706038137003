import { BEGIN_REQUEST, SUCCESS_REQUEST, FAIL_REQUEST } from './actions'

const initial = {}

export default function requestReducer(state = initial, { type, payload }) {
  switch (type) {
    case BEGIN_REQUEST:
      return {
        ...state,
        [payload.module]: {
          loading: true
        }
      }
    case SUCCESS_REQUEST:
      return {
        ...state,
        [payload.module]: {
          loading: false,
          success: true,
          data: payload.data
        }
      }
    case FAIL_REQUEST:
      return {
        ...state,
        [payload.module]: {
          loading: false,
          isError: true,
          error: payload.error
        }
      }
    default:
      return state
  }
}
