import React, { useState } from 'react'
import { array } from 'prop-types'
import PanelItem from './PanelItem'

export function DetailedContent({ content }) {
  const [active, setActive] = useState(-1)
  const toggle = index => () =>
    setActive(prev => setActive(prev === index ? -1 : index))
  return (
    <div id="horizontal_tab3" className="tab-pane fade active in">
      <div className="col-sm-12 align-left">
        <div className="schedule">
          <div className="tab-content">
            <div id="day1" className="tab-pane fade active in">
              <ul className="nav nav-schedule">
                <li className="active">
                  <a href="#day1_auditorium1" data-toggle="tab">
                    DÉTAILS DE LA FORMATION
                  </a>
                </li>
              </ul>

              <div className="tab-content tab-content-schedule">
                <div id="day1_auditorium1" className="tab-pane fade active in">
                  <div className="panel-group" id="day1_auditorium1_timeline">
                    {content.map(({ title, duration, content }, index) => (
                      <PanelItem
                        key={index}
                        toggle={toggle}
                        index={index}
                        active={active}
                        title={title}
                        duration={duration}
                        content={content}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

DetailedContent.propTypes = {
  content: array
}
