import { createSelector } from 'reselect'
import { useRef } from 'react'
import { useSelector } from 'react-redux'

export function useLoading(request) {
  const selector = useRef(
    createSelector(
      state => state.request[request],
      request => request || {}
    )
  )
  const { loading } = useSelector(selector.current)

  return { loading }
}
