import React from 'react'
import ReactDOM from 'react-dom'
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import store from './store'
import { FormationPage } from './pages/Formation/FormationPage'
import * as serviceWorker from './serviceWorker'

import 'antd/dist/antd.css'

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/sessions/:formationId" exact component={FormationPage} />
        <Redirect to="/sessions/1" />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
if (process.env.NODE_ENV === 'production') {
  serviceWorker.register()
}
