import { Skeleton } from 'antd'
import React from 'react'

export const FormationSkeleton = () => {
  return (
    <div className="container">
      <Skeleton active />
      <div style={{ marginTop: '20px' }}>
        <Skeleton avatar paragraph={{ rows: 1 }} active />
      </div>
    </div>
  )
}
