import React, { useCallback } from 'react'
import { string, number, oneOfType } from 'prop-types'
import { useDispatch } from 'react-redux'
import { showInscriptionModal } from '../../store/Inscription/actions'

export default function PriceItem({ type, currency, price }) {
  const dispatch = useDispatch()
  const show = useCallback(
    () => dispatch(showInscriptionModal({ type, currency, price })),
    [type, currency, price, dispatch]
  )
  return (
    <div
      className="col-xs-12 col-sm-5 col-md-4 d-flex justify-content-center"
      style={{ marginBottom: '24px' }}
    >
      <div
        className="  package-column grow-1"
        style={{ maxWidth: 'auto', flexGrow: '1' }}
      >
        <h6 className="package-title">{type} </h6>
        <div className="package-price">
          <span className="currency">{currency}</span>
          {price}
        </div>
        <div className="package-detail">
          <ul>
            <li>
              <span className="fa fa-check check-icon"></span>Formation
            </li>
            <li>
              <span className="fa fa-check check-icon"></span>Déjeuner
            </li>
            <li>
              <span className="fa fa-check check-icon"></span>Pause-café
            </li>
          </ul>
        </div>
        <div className="d-flex justify-content-center">
          <button onClick={show} className="btn btn-lg btn-outline-clr">
            Inscrivez-vous
          </button>
        </div>
      </div>
    </div>
  )
}

PriceItem.propTypes = {
  type: string.isRequired,
  currency: string.isRequired,
  price: oneOfType([string, number]).isRequired
}
