import React, { useEffect, useRef } from 'react'
import Header from '../../components/Header/Header'
import FormationBanner from '../../components/FormationBanner/FormationBanner'
import { Counter } from '../../components/Counter/Counter'
import { Details } from '../../components/Details/Details'
import { Audience } from '../../components/Audience/Audience'
import { Speakers } from '../../components/Speakers/Speakers'
import { NewsLetter } from '../../components/NewsLetter/NewsLetter'
import { Prices } from '../../components/Prices/Prices'
import { Contacts } from '../../components/Contacts/Contacts'
import { Footer } from '../../components/Footer/Footer'
import { InscriptionModal } from '../../components/InscriptionModal/InscriptionModal'
import ScrollUpButton from 'react-scroll-up-button'
import { useParams, useHistory } from 'react-router-dom'
import { FormationSkeleton } from './FormationSkeleton'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFormation } from '../../store/Request/requests'
import { createRequestSelector } from '../../store/Request/selector'
import ServerError from '../../components/ServerError/ServerError'
import { FormationMap } from '../../components/Map/Map'
import About from '../../components/About/About'
import Swal from 'sweetalert2'

export function FormationPage() {
  const { formationId } = useParams()
  const selectFormation = useRef(createRequestSelector('formation'))
  const { loading = true, data, error, isError } = useSelector(
    selectFormation.current
  )
  const dispatch = useDispatch()
  const {
    resume,
    name,
    date,
    duration,
    address,
    objectifs,
    audience,
    costs,
    speaker,
    map,
    content,
    about,
    image
  } = data || {}
  const history = useHistory()
  useEffect(() => {
    dispatch(
      fetchFormation({ id: formationId }, undefined, () => {
        Swal.fire({
          title: 'Désolé!',
          text: "Nous n'avons pas pu trouver cette formation",
          icon: 'error',
          showCloseButton: true,
          onClose: () => {
            history.push('/formations/1')
          }
        })
      })
    )
  }, [formationId, dispatch, history])

  return (
    <>
      <Header />
      {loading ? (
        <FormationSkeleton />
      ) : isError ? (
        <ServerError error={error} />
      ) : (
        <>
          <FormationBanner {...{ name, date, duration, address, image }} />
          <Counter date={data.date} />
          <Details {...{ resume, objectifs, content }} />
          <Audience {...{ audience, duration }} />
          <Speakers speaker={speaker} />
          <NewsLetter />
          <Prices prices={costs} />
          <Contacts />
          <FormationMap map={map} />
          <About about={about} />
        </>
      )}
      <div className="grow-1"></div>

      <Footer />
      <InscriptionModal />
      <ScrollUpButton
        ShowAtPosition={250}
        ContainerClassName="rounded"
        style={{
          width: '40px',
          height: '40px',
          zIndex: '9999999',
          background: '#cea42d',
          bottom: '0',
          padding: '8px',
          borderRadius: '4px'
        }}
      />
    </>
  )
}
