import React from 'react'
import { object } from 'prop-types'
import './style.css'

export function Speakers({ speaker: { name, grade, about, photo, linkedin } }) {
  return (
    <section id="speakers" className="section align-center">
      <div className="container">
        <h3>Speaker</h3>
        <div className="col-sm-12">
          <div className="speaker">
            <div className="photo-wrapper rounded">
              {photo ? (
                <img
                  src={photo}
                  alt={name}
                  width="150"
                  className="img-responsive"
                />
              ) : (
                <i className="fa fa-user-tie fa-7x" />
              )}
            </div>
            <h3 className="name">{name}</h3>
            <p className="text-alt">
              <small>{grade}</small>
            </p>
            <p className="about ">{about}</p>
          </div>
        </div>
        <a href={linkedin} className="btn linkedin-btn" target="blank">
          <i className="fab fa-linkedin"></i> LinkedIn
        </a>
      </div>
    </section>
  )
}

Speakers.propTypes = {
  speaker: object.isRequired
}
