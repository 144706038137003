import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectContact } from './selector'
import { Spin, Icon } from 'antd'
import './style.css'
import { sendMessage } from '../../store/Request/requests'
import { useParams } from 'react-router-dom'
const initial = { message: '', email: '', name: '' }

export function Contacts() {
  const [formState, setFormState] = useState(initial)
  const { loading } = useSelector(selectContact)
  const dispatch = useDispatch()
  const { formationId: id } = useParams()
  const onChange = useCallback(
    ({ target: { name, value } }) =>
      setFormState({ ...formState, [name]: value }),
    [formState]
  )
  const onSubmit = useCallback(
    e => {
      e.preventDefault()
      dispatch(
        sendMessage(
          { ...formState, id },
          () =>
            setFormState({
              ...initial,
              email: formState.email
            }),
          {
            title: 'Merci!',
            content:
              'Nous apprécions votre intérêt, nous vous contacterons bientôt.'
          },
          {
            title: 'Pardon!',
            content: "Une erreur s'est produite, veuillez réessayer."
          }
        )
      )
    },
    [formState, dispatch, id]
  )

  return (
    <section id="contacts">
      <div className="contact-us text-center ">
        <h2 style={{ marginBottom: '30px' }}> Contactez-nous</h2>
        <div className="container contact-form">
          <div className="row">
            <div className="col-md-6">
              <p className="text-left">
                Si vous voulez recevoir nos offres de formation ou que vous
                voulez une formation spéciale à vous, dites-le nous, nous serons
                entièrement à votre disposition.
              </p>
            </div>
            <div className="col-md-6">
              <form
                onSubmit={onSubmit}
                id="contact_form"
                action=""
                className="form form-mail-us"
              >
                <fieldset>
                  <label htmlFor="name">Nom</label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    onChange={onChange}
                    value={formState.name}
                    disabled={loading}
                  />
                </fieldset>

                <fieldset>
                  <label htmlFor="email">Email</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    onChange={onChange}
                    disabled={loading}
                    value={formState.email}
                  />
                </fieldset>

                <fieldset>
                  <label htmlFor="message">Message</label>
                  <textarea
                    id="message"
                    name="message"
                    type="text"
                    required
                    onChange={onChange}
                    disabled={loading}
                    value={formState.message}
                  ></textarea>
                </fieldset>

                <fieldset>
                  <button
                    className="btn-send-message btn btn-solid send-form-btn d-flex justify-content-center align-items-center "
                    type="submit"
                  >
                    {loading ? (
                      <Spin
                        indicator={
                          <Icon
                            type="loading"
                            style={{ fontSize: 20, color: 'white' }}
                          />
                        }
                      />
                    ) : (
                      'Envoyer'
                    )}
                  </button>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
