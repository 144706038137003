import React from 'react'
import { string } from 'prop-types'
import './style.css'
export default function About({ about }) {
  return (
    <section className="about-section">
      <div className="container">
        <div className="col-md-6">
          <div className="widget about-widget">
            {about && (
              <>
                <h6 className="widget-head">
                  Apropos de
                  <span className="highlight"> l{"'"}évenment</span>
                </h6>
                <p className="text-alt">
                  <small>{about}</small>
                </p>
              </>
            )}
            <img src="/assets/img/logo-white.svg" alt="autograph" />
          </div>
        </div>

        <div className="col-md-4 col-lg-3 col-lg-offset-1"></div>
      </div>
    </section>
  )
}
About.propTypes = {
  about: string.isRequired
}
