import React from 'react'
import PriceItem from './PriceItem'
import { array } from 'prop-types'

export function Prices({ prices }) {
  return (
    <section id="prices" className="section align-center">
      <div className="container">
        <h3>Tickets</h3>
        <p className="text-alt">
          Obtenez le vôtre tant qu{"'"}ils sont encore{' '}
          <span className="highlight"> disponibles</span>
        </p>
        <br />
        <br />

        <div className="flex-container ">
          {prices.map(price => (
            <PriceItem key={price.type} {...price} />
          ))}
        </div>
      </div>
    </section>
  )
}

Prices.propTypes = {
  prices: array.isRequired
}
