import { combineReducers } from 'redux'
import requestReducer from './Request'
import { settingsReducer } from './settings'
import { inscriptionReducer } from './Inscription'

export const rootReducer = combineReducers({
  request: requestReducer,
  settings: settingsReducer,
  inscription: inscriptionReducer
})
