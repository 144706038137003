import axios from 'axios'
import Swal from 'sweetalert2'
import { setEmail } from '../settings/action'

export const BEGIN_REQUEST = 'BEGIN_REQUEST'
export const SUCCESS_REQUEST = 'SUCCESS_REQUEST'
export const FAIL_REQUEST = 'FAIL_REQUEST'

export const beginRequest = module => ({
  type: BEGIN_REQUEST,
  payload: { module }
})

export const successRequest = (module, payload) => ({
  type: SUCCESS_REQUEST,
  payload: { module, ...payload }
})

export const failRequest = (module, payload) => ({
  type: FAIL_REQUEST,
  payload: { module, ...payload }
})

export const createRequest = (moduleId, url) => (
  params,
  onSuccess,
  successText = {},
  failedText = {},
  saveEmail = true
) => dispatch => {
  dispatch(beginRequest(moduleId))
  axios
    .get(url, { params })
    .then(({ data }) => {
      Swal.fire({
        title: successText.title || 'Merci!',
        text:
          successText.content ||
          'Nous apprécions votre intérêt, nous vous contacterons bientôt.',
        icon: 'success',
        showCloseButton: true
      })
      dispatch(successRequest(moduleId, { data }))
      if (typeof onSuccess === 'function') onSuccess(data)
      if (saveEmail) dispatch(setEmail(params.email))
    })
    .catch(error => {
      Swal.fire({
        title: failedText.title || 'Désolé!',
        text:
          failedText.content ||
          "Une erreur s'est produite, veuillez réessayer.",
        icon: 'error',
        showCloseButton: true
      })
      console.error(error)
      dispatch(failRequest(moduleId, { error }))
    })
}

export const createFetch = (moduleId, url) => (
  params,
  onSuccess,
  onFail
) => dispatch => {
  dispatch(beginRequest(moduleId))
  axios
    .get(url, { params })
    .then(({ data }) => {
      if (data.error) {
        if (onFail) onFail()
        throw data.error
      }
      if (onSuccess) onSuccess()
      dispatch(successRequest(moduleId, { data }))
    })
    .catch(error => {
      dispatch(failRequest(moduleId, { error }))
    })
}
