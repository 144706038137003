import React from 'react'
import './style.css'
export function Footer() {
  return (
    <footer className="footer footer-container">
      <div className="footer-base">
        <div className="container">
          <div className="col-md-6"></div>

          <div className="col-md-6 align-right">
            <p className="text-alt">
              <small>Tous les droits sont réservés DzConseil &copy; 2020</small>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
