import React from 'react'
import { array, string } from 'prop-types'
import './style.css'
import classNames from 'classnames'

export function Audience({ audience, duration }) {
  return (
    <section id="for-who" className="for-who">
      <div className="container text-center">
        <h1>À qui s’adresse la formation</h1>
        <p className="sub-header">
          D’une durée de <span style={{ fontSize: '30px' }}>{duration}</span>,
          cette formation s’adresse à un large auditoire :
        </p>
        <div className="flex-container  ">
          {audience.map(({ type, description }, index) => (
            <React.Fragment key={index}>
              <div
                className={classNames({
                  'd-none': index % 2,
                  break: !(index % 2) && index !== 0
                })}
              />
              <div key={type} className="col-12 col-md-6 ">
                <h4> {type} </h4>
                <p className="description"> {description}</p>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  )
}

Audience.propTypes = {
  audience: array.isRequired,
  duration: string.isRequired
}
