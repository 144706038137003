import React, { useState, useCallback } from 'react'
import { Link } from 'react-scroll'
import classNames from 'classnames'
import './style.css'

export default function Header() {
  const [menuIn, setMenuIn] = useState(false)
  const toggleMenu = useCallback(() => setMenuIn(menuIn => !menuIn), [])
  return (
    <header className="header header-black">
      <div className="header-wrapper">
        <div className="container">
          <div className="col-sm-2 col-xs-12 navigation-header">
            <a href="https://dzconseil.com" className="logo">
              <img src="/assets/img/logo-inline.png" alt="DzConseil" />
            </a>
            <button
              onClick={toggleMenu}
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#navigation"
              aria-expanded="false"
              aria-controls="navigation"
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>
          <div className="col-sm-10 col-xs-12 navigation-container">
            <div
              id="navigation"
              className={classNames('navbar-collapse collapse', { in: menuIn })}
            >
              <Link
                className=" pull-right buy-btn"
                activeClass="active"
                to="prices"
                spy={true}
                smooth={true}
                duration={500}
              >
                Inscrivez-vous !
              </Link>
              <div className="pull-right">
                <ul className="navigation-list light-text">
                  <li className="navigation-item">
                    <Link
                      className="navigation-link "
                      activeClass="active"
                      to="about"
                      spy={true}
                      smooth={true}
                      duration={500}
                    >
                      À-Propos
                    </Link>
                  </li>
                  <li className="navigation-item">
                    <Link
                      className="navigation-link "
                      activeClass="active"
                      to="for-who"
                      spy={true}
                      smooth={true}
                      duration={500}
                    >
                      Pour Qui !
                    </Link>
                  </li>
                  <li className="navigation-item">
                    <Link
                      className="navigation-link "
                      activeClass="active"
                      to="speakers"
                      spy={true}
                      smooth={true}
                      duration={500}
                    >
                      Speaker
                    </Link>
                  </li>
                  <li className="navigation-item ">
                    <Link
                      className="navigation-link "
                      activeClass="active"
                      to="prices"
                      spy={true}
                      smooth={true}
                      duration={500}
                    >
                      Tarifs
                    </Link>
                  </li>
                  <li
                    className="navigation-item "
                    style={{ marginRight: '15px' }}
                  >
                    <Link
                      className="navigation-link "
                      activeClass="active"
                      to="contacts"
                      spy={true}
                      smooth={true}
                      duration={500}
                    >
                      Contactez-nous
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
