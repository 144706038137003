import React from 'react'
import { Link } from 'react-scroll'
import { string } from 'prop-types'
import moment from 'moment'
import './style.css'
import 'moment/locale/fr'

moment.locale('fr')
export default function FormationBanner({
  name,
  duration,
  date,
  address,
  image
}) {
  date = moment(date)
    .format('dddd, MMMM Do YYYY, h:mm:ss a')
    .toUpperCase()
  return (
    <section>
      <div className="header-container" data-reactid="112">
        <div className="gradient-background" data-reactid="113"></div>
        <div
          className="background-image"
          style={{ backgroundImage: `url('${image}')` }}
        ></div>
        <div className="content-container" data-reactid="115">
          <div className="" data-reactid="116">
            <div className="program-info-content" data-reactid="117">
              <div className="row no-gutters">
                <div className="col-12 col-sm-7">
                  <span className="title-style">{name} </span>
                </div>
              </div>
              <div className="jumbotron-details">
                <div className="jumbotron-item-icon">
                  <i
                    className="fal fa-calendar-alt"
                    style={{ fontSize: '20px', marginRight: '15px' }}
                  ></i>
                  {date}
                </div>

                <div className="jumbotron-item-icon">
                  <i
                    className="fal fa-map-marker"
                    style={{ fontSize: '20px', marginRight: '15px' }}
                  ></i>
                  <a href="#map">{address}</a>
                </div>

                <div className="jumbotron-item-icon">
                  <i
                    className="fal fa-clock"
                    style={{ fontSize: '20px', marginRight: '15px' }}
                  ></i>
                  {duration}
                </div>
              </div>

              <Link to="prices" smooth={true} className="btn btn-custom">
                Inscrivez-vous
              </Link>

              <p className="info">
                <i className="fas fa-info-circle"></i>&nbsp;S{"'"}il vous plaît
                vérifier les Tarifs dans la section des Tarifs
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

FormationBanner.propTypes = {
  name: string.isRequired,
  duration: string.isRequired,
  address: string.isRequired,
  date: string.isRequired,
  image: string.isRequired
}
