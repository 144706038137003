import React from 'react'
import './style.css'
import { object } from 'prop-types'

export default function ServerError({ error }) {
  const isNotFound = error.status === 404
  return (
    <div className="server-error-container">
      <div className=" mb-5 text-center ">
        <img
          alt="Not Found"
          src={'/assets/img/' + (isNotFound ? '404.svg' : 'error.png')}
          className="not-found-icon col-auto"
        />
      </div>
      <div className="row justify-content-center no-gutters mt-4">
        <h2 className="col-12 text-center">Whoops</h2>
        <p className="col-12 text-center">{error.message}</p>
      </div>
    </div>
  )
}

ServerError.propTypes = {
  error: object.isRequired
}
