import React from 'react'
import { string } from 'prop-types'
import './style.css'
export function FormationMap({ map }) {
  if (!map) return null
  return (
    <section id="map">
      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe
            title="Map"
            id="gmap_canvas"
            src={map}
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
          ></iframe>
        </div>
      </div>
    </section>
  )
}

FormationMap.propTypes = {
  map: string.isRequired
}
