import React, { useState, useCallback, useRef } from 'react'
import { Modal, Input } from 'antd'
import { useLoading } from '../../hooks/useLoading'
import { useDispatch, useSelector } from 'react-redux'
import { saveInscription } from '../../store/Request/requests'
import { selectInscription } from '../../store/Inscription/selector'
import './style.css'
import { hideInscriptionModal } from '../../store/Inscription/actions'
import { useParams } from 'react-router-dom'

const initial = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  organization: ''
}
export function InscriptionModal() {
  const [form, setForm] = useState(initial)
  const { loading: confirmLoading } = useLoading('inscription')
  const dispatch = useDispatch()
  const { formationId: id } = useParams()
  const { isVisible, type, currency, price } = useSelector(selectInscription)
  const formRef = useRef()
  const submitRef = useRef()
  const onChange = useCallback(
    ({ target: { name, value } }) => setForm({ ...form, [name]: value }),
    [form]
  )

  const onOk = useCallback(() => {
    submitRef.current.click()
  }, [])
  const onCancel = useCallback(() => {
    setForm(initial)
    dispatch(hideInscriptionModal())
  }, [dispatch])

  const submit = useCallback(
    e => {
      e.preventDefault()
      dispatch(saveInscription({ ...form, type, id }, onCancel))
    },
    [dispatch, form, id, type, onCancel]
  )

  return (
    <Modal
      title="Incscrivez"
      visible={isVisible}
      onOk={onOk}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      mask
      okButtonProps={{
        style: { backgroundColor: '#095EFF', borderColor: '#095EFF' }
      }}
    >
      <div
        className="package-column "
        style={{
          padding: '16px',
          marginBottom: '16px',
          maxWidth: '100%'
        }}
      >
        <h6 className="package-title">{type}</h6>
        <div className="package-price">
          <span className="currency">{currency || 'DZD'}</span>
          {price}
        </div>
      </div>
      <form ref={formRef} onSubmit={submit}>
        <input type="submit" ref={submitRef} className="d-none hidden" />
        {(type || '').toLowerCase() === 'organisation' && (
          <>
            <label>Organisation</label>
            <Input
              onChange={onChange}
              value={form.organization}
              name="organization"
              type="text"
              required
            />
          </>
        )}
        <label>Nom</label>
        <Input
          onChange={onChange}
          value={form.firstName}
          name="firstName"
          type="text"
          required
        />
        <label>Prénom</label>
        <Input
          onChange={onChange}
          value={form.lastName}
          name="lastName"
          type="text"
          required
        />
        <label>Email</label>
        <Input
          onChange={onChange}
          value={form.email}
          name="email"
          type="email"
          required
        />
        <label>Téléphone</label>
        <Input
          onChange={onChange}
          value={form.phone}
          name="phone"
          type="tel"
          pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
        />
      </form>
    </Modal>
  )
}
