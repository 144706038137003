import React from 'react'
import moment from 'moment'
import { string } from 'prop-types'

export function Counter({ date }) {
  const diff = moment.duration(moment(date).diff(moment()))
  const days = parseInt(diff.asDays())
  const hours = parseInt(diff.hours() % 24)
  const minutes = parseInt(diff.asMinutes() % 60)

  return (
    <section className="section count-down align-center">
      <div className="container">
        <h3>Compte à rebours de l{"'"}événement</h3>
        <p className="text-alt">Temps restant pour l{"'"}événement</p>

        <div className="row counters-wrapper countdown">
          <div className="col-xs-4">
            <div className="counter-block counter-block-no-border">
              <div className="counter-box">
                <div className="counter-content">
                  <span className="count" id="days">
                    {days}
                  </span>
                  <p className="title">Jours</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xs-4">
            <div className="counter-block counter-block-no-border ">
              <div className="counter-box">
                <div className="counter-content">
                  <span className="count" id="hours">
                    {hours}
                  </span>
                  <p className="title">Heurs</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xs-4">
            <div className="counter-block counter-block-no-border ">
              <div className="counter-box">
                <div className="counter-content">
                  <span className="count" id="minutes">
                    {minutes}
                  </span>
                  <p className="title">minutes</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

Counter.propTypes = {
  date: string.isRequired
}
