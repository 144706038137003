import React from 'react'
import { number, func, string } from 'prop-types'

export default function PanelItem({
  toggle,
  active,
  index,
  title,
  duration,
  content
}) {
  return (
    <div className="panel schedule-item">
      <div onClick={toggle(index)} className="schedule-item-toggle collapsed">
        <h6 className="title">
          <i className="fal icon-plus fa-plus" />

          {title}
          <span className="pull-right time-span">
            <i className="fal fa-clock" style={{ marginRight: '10px' }} />
            {duration}
          </span>
        </h6>
      </div>
      <div
        id="day1_auditorium1_time4"
        className={
          'panel-collapse collapse schedule-item-body ' +
          (active === index ? ' in ' : '')
        }
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </div>
  )
}

PanelItem.propTypes = {
  active: number,
  toggle: func.isRequired,
  title: string.isRequired,
  duration: string.isRequired,
  index: number.isRequired,
  content: string.isRequired
}
