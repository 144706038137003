import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { newsLetterSelector } from './selector'
import { Spin, Icon } from 'antd'
import './style.css'
import { subscribeToNewsLetter } from '../../store/Request/requests'
import { useParams } from 'react-router-dom'

export function NewsLetter() {
  const dispatch = useDispatch()
  const { loading } = useSelector(newsLetterSelector)
  const [email, setEmail] = useState('')
  const { formationId: id } = useParams()
  const onChange = useCallback(({ target: { value } }) => setEmail(value), [])

  const onSubmit = useCallback(
    e => {
      e.preventDefault()

      dispatch(
        subscribeToNewsLetter(
          { email, id },
          () => {},
          { title: 'Merci!', content: 'Nous apprécions votre intérêt.' },
          {
            title: 'Pardon!',
            content: "Une erreur s'est produite, veuillez réessayer."
          }
        )
      )
    },
    [email, id, dispatch]
  )

  return (
    <section
      id="sign_up"
      className="section bg-cover overlay bg4 light-text align-center"
    >
      <div className="container">
        <h2>
          Enregistrez sur la <span className="highlight">Newsletter</span>
        </h2>
        <small>
          Pas de spam - Seulement les dernières nouvelles, mises à jour de
          Tarifs et activités
        </small>
        <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
          <form
            id="subscribe_main"
            action=""
            method="post"
            className="form row newsletter-form mailchimp-form"
            onSubmit={onSubmit}
          >
            <fieldset className="col-sm-8">
              <input
                id="email_newsletter"
                name="email_newsletter"
                type="email"
                onChange={onChange}
                disabled={loading}
                value={email}
                required
                placeholder="Email"
              />
            </fieldset>
            <fieldset className="col-sm-4   ">
              <button
                className="btn-subscribe-news-letter btn btn-sm btn-outline-clr send-form-btn position-relative d-flex justify-content-center align-items-center "
                type="submit"
              >
                {loading ? (
                  <Spin
                    indicator={
                      <Icon
                        type="loading"
                        style={{ fontSize: 18, color: 'white' }}
                      />
                    }
                  />
                ) : (
                  'Soummetre'
                )}
              </button>
            </fieldset>
            <div className="response"></div>
          </form>
        </div>
      </div>
    </section>
  )
}
