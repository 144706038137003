import React from 'react'
import { DetailedContent } from './Tabs/DetailedContent'
import { Tabs } from 'antd'
import './about.css'
import { string, array } from 'prop-types'
import { TabContent } from './Tabs/TabContent'
const { TabPane } = Tabs

export function Details({ resume, objectifs, content }) {
  return (
    <section id="about" className="section align-center">
      <div className="container">
        <h3>Business rencontre l{"'"}innovation</h3>
        <p className="text-alt">
          Efficacité opérationnel dans un contexte de numérisation globale de
          l’organisatione
        </p>
        <br />
        <br />

        <div className="tabs-wrapper tabs-horizontal">
          <Tabs defaultActiveKey="2" tabBarGutter={64}>
            <TabPane
              tab={
                <li className="tab-item">
                  <h6 className="heading-alt">
                    <span className="fa fa-code active text-dark"></span> Résumé
                  </h6>
                </li>
              }
              key="1"
            >
              <TabContent
                image="/assets/img/efficacite.svg"
                title="A propos de l'evenment"
                description={resume}
              />
            </TabPane>
            <TabPane
              tab={
                <li className="tab-item">
                  <h6 className="heading-alt">
                    <span className="fa fa-rocket"></span> Objectifs
                  </h6>
                </li>
              }
              key="2"
            >
              <TabContent
                image="/assets/img/goals.svg"
                title="Objectifs"
                description={objectifs}
              />
            </TabPane>
            <TabPane
              tab={
                <li className="tab-item">
                  <h6 className="heading-alt">
                    <span className="fa fa-external-link"></span> Contenu
                    détaillé
                  </h6>
                </li>
              }
              key="3"
            >
              <DetailedContent content={content} />
            </TabPane>
          </Tabs>
          ,
        </div>
      </div>
    </section>
  )
}
Details.propTypes = {
  resume: string.isRequired,
  objectifs: string.isRequired,
  content: array.isRequired
}
