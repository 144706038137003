import { SHOW_INSCRIPTION_MODAL, HIDE_INSCRIPTION_MODAL } from './actions'

const initial = { isVisible: false }
export function inscriptionReducer(state = initial, { type, payload }) {
  switch (type) {
    case SHOW_INSCRIPTION_MODAL:
      return { isVisible: true, ...payload }
    case HIDE_INSCRIPTION_MODAL:
      return { isVisible: false }
    default:
      return state
  }
}
