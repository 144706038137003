import { config } from '../../utils/constants'
import { createRequest, createFetch } from './actions'

export const subscribeToNewsLetter = createRequest(
  'newsLetter',
  config.MEMBERS_END
)

export const sendMessage = createRequest('contact', config.MESSAGES_END)

export const saveInscription = createRequest(
  'inscription',
  config.INSCRIPTION_END
)

export const fetchFormation = createFetch('formation', config.FORMATION_END)
